<template>
   <div>
      <Login />
   </div>
</template>

<script>
import Login from "../components/Login/Login.vue";

export default {
   components: {
      Login,
   },
};
</script>