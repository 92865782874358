<template>
   <div>
      <Notification ref="user_error_notification" type="error" message="Error en usuario y/o contraseña" />
      <div class="login">
         <v-card elevation="8" width="90%" max-width="600px" :loading="isloading">
            <v-card-text>
               <v-form class="px-2 pt-4 pb-2">
                  <v-text-field
                     label="Usuario"
                     v-model="username"
                     autocomplete="username"
                     :error-messages="usernameErrors"
                     outlined
                     required
                     @input="$v.username.$touch()"
                     @blur="$v.username.$touch()"
                     color="secondary"
                  ></v-text-field>
                  <v-text-field
                     label="Contraseña"
                     v-model="password"
                     autocomplete="current-password"
                     :error-messages="passwordErrors"
                     outlined
                     required
                     @keydown.enter="submit"
                     @input="$v.password.$touch()"
                     @blur="$v.password.$touch()"
                     :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                     :type="show_password ? 'text' : 'password'"
                     @click:append="show_password = !show_password"
                     color="secondary"
                  ></v-text-field>
                  <div class="d-flex justify-center align-center">
                     <v-btn color="botones" light class="mx-2" @click="submit" :loading="isloading">Iniciar sesión</v-btn>
                     <v-btn class="mx-2" color="botones" @click="clear" x-small icon>
                        <v-icon dense>mdi-eraser</v-icon>
                     </v-btn>
                  </div>
               </v-form>
            </v-card-text>
         </v-card>
      </div>
   </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
   validations: {
      username: { required },
      password: { required },
   },
   data: () => ({
      username: "",
      password: "",
      show_password: false,
      isloading: false,
   }),
   computed: {
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El nombre de usuario es requerido");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         return errors;
      },
   },
   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("login", {
                  username: this.username,
                  password: this.password,
               });
               if (res == true) {
                  this.$router.push({
                     name: "Dashboard",
                  });
               } else {
                  throw res;
               }
            } else {
               throw "error en formulario";
            }
         } catch (error) {
            this.$refs.user_error_notification.Show();
            this.clear();
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.username = "";
         this.password = "";
      },
   },
};
</script>

<style scoped>
.login {
   height: calc(100vh - 100px);
   display: flex;
   justify-content: center;
   align-items: center;
}
</style>
